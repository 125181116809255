import React from 'react';
// Icons import

const Home = React.lazy(() => import('./pages/home/home'));
const GetQuote = React.lazy(() => import('./pages/getquote/getquote'));
const OurExpertise = React.lazy(() => import('./pages/ourexpertise/ourexpertise'));
const Services = React.lazy(() => import('./pages/services/services'));
const MobileAppDevelopment = React.lazy(() => import('./pages/mobileappdevelopment/mobileappdevelopment'));
const DesktopAppDeveopment = React.lazy(() => import('./pages/desktopappdevelopment/desktopappdevelopment'));
const WebAppDevelopment = React.lazy(() => import('./pages/webappdevelopment/webappdevelopment'));
const DigitalMarketing = React.lazy(() => import('./pages/digitalmarketing/digitalmarketing'));
const EcommerceProduct = React.lazy(() => import('./pages/ecommerceproduct/ecommerceproduct'));
const IndustrialTraining = React.lazy(() => import('./pages/industrialtraining/industrialtraining'));
const ContactUs = React.lazy(() => import('./pages/contactus/contactus'));
const AboutUs = React.lazy(() => import('./pages/aboutus/aboutus'));
const Career = React.lazy(() => import('./pages/career/career'));

export const userRoutes = [{
    path: '/',
    text: 'Home',
    component: Home
},
{
    path: '/ourexpertise',
    text: 'Our Expertise',
    component: OurExpertise
},
{
    path: '/getquote',
    text: 'Get a Quote',
    component: GetQuote
},
{
    path: '/services',
    text: 'Our Services',
    component: Services
},
{
    path: '/mobileappdevelopment',
    text: 'Mobile App Development',
    component: MobileAppDevelopment
},
{
    path: '/desktopappdevelopment',
    text: 'Desktop App Development',
    component: DesktopAppDeveopment
},
{
    path: '/webappdevelopment',
    text: 'Web App Development',
    component: WebAppDevelopment
},
{
    path: '/digitalmarketing',
    text: 'Digital Marketing',
    component: DigitalMarketing
},
{
    path: '/ecommerceproduct',
    text: 'Ecommerce Product',
    component: EcommerceProduct
},
{
    path: '/industrialtraining',
    text: 'Industrial Training',
    component: IndustrialTraining
},
{
    path: '/career',
    text: 'Career',
    component: Career
},
{
    path: '/aboutus',
    text: 'About Us',
    component: AboutUs
},
{
    path: '/contactus',
    text: 'Contact Us',
    component: ContactUs
}

];

export const adminRoutes = [

]

export const allRoutes = [...userRoutes, ...adminRoutes];

function getFlatComponent() {
    let temp = [];
    for (let path of allRoutes) {
        temp.push(path);
        if (path.children) {
            temp.push(path.children);
        }
    }
    return temp.flat(Infinity).filter(itm => itm.path !== undefined && itm.component !== undefined);
}
export const activeRoutes = getFlatComponent();
export default allRoutes;