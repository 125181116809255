import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/logos/techsquadrons-logo.png";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box
} from "@mui/material";

import styled from '@emotion/styled';
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from 'react-router-dom';
import './navdrawer.css';

const useStyles = styled(theme => ({
  root: {
    //Your styling rules
  },
  link: {
    textDecoration: "none",
    color: "blue",
    fontSize: "20px",
  },
  icon: {
    color: "white"
  }
}))

function NavDrawer() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setOpenDrawer(false);
  }, [location.pathname])


  const navigateMobile = (path) => {
    setOpenDrawer(false);
    navigate(path);
  }


  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List sx={{ backgroundColor: "#0F9FFA", color: "#fff" }} className="mobile-menu">
          <>
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/services')}>
                <ListItemText>Services</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/career')}>
                <ListItemText>Career</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/aboutus')}>
                <ListItemText>About Us</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/contactus')}>
                <ListItemText>Contact Us</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
          </>


        </List>
      </Drawer>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', width: 'calc(100vw - 20px)' }}>
        <Box>
          <img src={logo} alt="img-logo" style={{ height: "40px" }} onClick={() => navigateMobile('/')} />
        </Box>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon}>
          <MenuIcon />
        </IconButton>
      </Box>
    </>
  );
}

export default NavDrawer;