import './App.css';
import Navbar from './components/navigation/navbar/navbar';
import { Suspense } from 'react';
import { activeRoutes } from './routing';
import { Routes, Route } from 'react-router-dom';
import Footer from './components/footer/footer';
import LoadingComponent from './components/loading/'

function App() {
  return (
    <div className="App">
      <Navbar />
      <br />
      <br />
      <Suspense fallback={<LoadingComponent />}>
        <Routes>
          {activeRoutes.map(r =>
            <Route path={r.path} element={<r.component />} />
          )}
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
