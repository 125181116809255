import * as signupTypes from '../types/signuptypes';
const initialSignupState = {
    result: undefined,
    loading: false
}

export default function signupReducer(state = initialSignupState, action) {
    switch (action.type) {
        case signupTypes.ON_SIGN_UP:
            return {
                ...state,
                loading: action.payload.loading
            }
        case signupTypes.ON_SIGN_UP_SUCCESS:
            return {
                ...state,
                result: action.payload.data
            }
        case signupTypes.ON_SIGN_UP_FAILURE:
            return {
                ...state,
                result: action.payload.data
            }
        default:
            return state;
    }
}