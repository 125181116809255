import * as mainTypes from '../types/main.types';
const initialCategoryState = {
    courseList: [],
    courseObject: {}
}

export default function mainReducer(state = initialCategoryState, action) {
    switch (action.type) {
        case mainTypes.SET_RAW_COURSE_LIST:
            return {
                ...state,
                courseList: Object.values(action.payload),
                courseObject: action.payload
            }
        default:
            return state;
    }
}