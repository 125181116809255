import React, { useState, useEffect } from "react";
import './navbar.css';
import { useLocation } from "react-router-dom";
import logo from "../../../assets/logos/techsquadrons-logo.png";
import { useNavigate } from "react-router-dom";
import {
    AppBar,
    Toolbar,
    useTheme,
    useMediaQuery,
    Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import styled from '@emotion/styled';
import NavDrawer from "../navdrawer/navdrawer";

const useStyles = styled((theme) => ({
    navlinks: {
        marginLeft: theme.spacing(5),
        display: "flex",
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: theme.spacing(20),
        borderBottom: "1px solid transparent",
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
    },
}));
function Navbar() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [pathName, setPathName] = useState('/');
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const pName = location.pathname;
        setPathName(pName);
    }, [location.pathname]);

    return (

        <AppBar position="fixed">
            <Toolbar>
                {isMobile ? (
                    <NavDrawer />

                ) : (
                    <>
                        <Box>
                            <img src={logo} alt="img-logo" style={{ height: "40px", cursor: 'pointer' }} onClick={() => navigate('/')} />
                        </Box>
                        <Box sx={{ flex: 1 }}></Box>
                        <Link to="/services" className={`${classes.link} link-main ${pathName === '/services' && 'selected'}`}>
                            Services
                        </Link>
                        <Link to="/career" className={`${classes.link} link-main ${(pathName === '/career') && 'selected'}`}>
                            Career
                        </Link>
                        <Link to="/aboutus" className={`${classes.link} link-main ${pathName === '/aboutus' && 'selected'}`}>
                            About Us
                        </Link>
                        <Link to="/contactus" className={`${classes.link} link-main ${pathName === '/contactus' && 'selected'}`}>
                            Contact Us
                        </Link>
                    </>
                )}
            </Toolbar>
        </AppBar>

    );
}
export default Navbar;