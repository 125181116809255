import * as profileTypes from '../types/profiletypes';
const initialLoginState = {
    result: {},
    loading: false
}

export default function profileReducer(state = initialLoginState, action) {
    switch (action.type) {
        case profileTypes.ON_GET_PROFILE_LOADED:
            return {
                ...state,
                result: action.payload.data
            }
        case profileTypes.ON_GET_PROFILE_FAILURE:
            return {
                ...state,
                result: action.payload.data
            }
        default:
            return state;
    }
}